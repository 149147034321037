/* For pdf */
#allPages .react-pdf__Page {
    margin-top: 10px;
}
 #allPages .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}
.react-pdf__Page__canvas {
    margin: 0 auto;
}
